import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
// import BarChartIcon from '@material-ui/icons/BarChart'
import { TextField, Tooltip } from '@material-ui/core'
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons'

import { OutlinedTextField, OutlinedSelect, OutlinedCheckBox } from '../../common/fields'
import { FiriaGreenButton, RespButton } from '../../common/buttons'
import { getSim, putSim } from '../../common/utils'
import { useLogin } from '../../common/LoginContext'
import GenericDialog from '../../common/GenericDialog'

import { LMSIntegrationSelectionPanel } from '../shared-components/lms-panels/LMSIntegrationSelectionPanel'
import { DEFAULT_GROUP_FIELDS } from '../shared-components/sharedObjects'
import { CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID } from '../group/generate-assignments-dialog/curated-assignments'


const useStyles = makeStyles(theme => ({
  dialogContent: {
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 20,
    padding: '25px',
  },
  dialogContentContainer: {
    width:'fit-content',
    display:'flex',
    flexDirection: 'column',
    gap: 16,
    padding: '10px 20px',
  },
  wideField: {
    width: '100%',
  },
}))

function HelpHover({children}) {
  return (
    <Tooltip title={children} placement='bottom' arrow>
      <HelpOutlineIcon style={{fontSize: 20, color: 'gray'}} />
    </Tooltip>
  )
}

// function ContestIcon({ size=24, full=false }) {
//   return (
//     <div style={{borderRadius: size/3, border: '1px solid rgba(0,0,0,0.5)', backgroundColor: 'purple', width: full?'fit-content':size, height: size, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
//       <BarChartIcon style={{color: 'white', fontSize: size, marginBottom: 2}}/>
//       {full && <Typography style={{color: 'white', paddingLeft: size/7, paddingRight: size/7 + 4, fontSize: size/1.5, fontStyle:'italic'}}>{'CONTEST'}</Typography>}
//     </div>
//   )
// }

function ContestQuestion({ question, required=true, children, infoText='' }) {
  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
        <Typography style={{fontWeight: 'bold'}}>{question}</Typography>
        {required && <Typography style={{fontWeight: 'bold', color: 'red'}}>{'*'}</Typography>}
        {infoText && <HelpHover>{infoText}</HelpHover>}
      </div>
      {children}
    </div>
  )
}

const gradeBands = ['K-5', '6-8', '9-12']
function makeGroupContentRegistrationOptions() {
  return Object.freeze({
    firstName: '',
    lastName: '',
    band: gradeBands[1],
    educationalInstitution: '',
    terms: false,
  })
}

function ContestRegistrationPanel({ setContestRegistrationOptions, firstName, lastName, band, educationalInstitution, terms }) {
  const classes = useStyles()
  const [loginState] = useLogin()

  function updateRegistrationField(fieldName, value) {
    setContestRegistrationOptions((prev) => {
      return {
        ...prev,
        [fieldName]: value,
      }
    })
  }

  function formatBandMenuItem(bandName) {
    if (bandName === 'K-5') {
      return 'Elementary (K-5)'
    }

    if (bandName === '6-8') {
      return 'Middle School (6-8)'
    }

    if (bandName === '9-12') {
      return 'High School (9-12)'
    }

    return bandName
  }

  const bands = [...gradeBands]
  if (loginState?.user?.email === 'jeff@firia.com') {
    bands.push('Firia')
  }
  return (

    <div style={{ borderRadius: 4, paddingLeft: '5px 0px'}}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5}}>
        {/* <ContestIcon size={18}/> */}
        <div style={{width: 20}}/>
        <Typography variant='h5'>
          {'Haunted Code Chronicles Contest Registration'}
        </Typography>
        <div style={{width: 20}}/>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', gap: 20, justifyContent: 'center', padding: '20px 0px'}}>
        <ContestQuestion question={'First Name'}>
          <TextField
            style={{width: '100%'}}
            placeholder='First Name'
            value={firstName}
            onChange={e => updateRegistrationField('firstName', e.target.value)}
            color='secondary'
          />
        </ContestQuestion>
        <ContestQuestion question={'Last Name'}>
          <TextField
            style={{width: '100%'}}
            placeholder='Last Name'
            value={lastName}
            onChange={e => updateRegistrationField('lastName', e.target.value)}
            color='secondary'
          />
        </ContestQuestion>
        <ContestQuestion question={'School Name'}>
          <TextField
            style={{width: '100%'}}
            placeholder='School Name'
            value={educationalInstitution}
            onChange={e => updateRegistrationField('educationalInstitution', e.target.value)}
            color='secondary'
          />
        </ContestQuestion>
        <ContestQuestion question={'Grade Band'}>
          <OutlinedSelect
            className={classes.wideField}
            style={{paddingTop: 5}}
            value={band}
            // onChange={ev => onProductMenuChange(ev.target.value)}
            onChange={e => updateRegistrationField('band', e.target.value)}
          >
            {bands.map(gradeBand => (
              <MenuItem
                key={gradeBand}
                value={gradeBand}
              >
                {formatBandMenuItem(gradeBand)}
              </MenuItem>
            ))}
          </OutlinedSelect>
        </ContestQuestion>
        <ContestQuestion question={'Terms and Conditions'}>
          <OutlinedCheckBox
            style={{width: '100%', marginTop: 5}}
            checked={terms}
            onClick={e => updateRegistrationField('terms', !terms)}
            label={'I\'ve read and agree to the terms and conditions'}
          />
        </ContestQuestion>
      </div>
    </div>
  )
}

function AddGroupDialog(props) {
  const classes = useStyles()
  const [groupFields, setGroupFields] = React.useState(DEFAULT_GROUP_FIELDS())
  const [createFetchStatus, setCreateFetchStatus] = React.useState(null)
  const [fetchStatus, setFetchStatus] = React.useState(null)
  const [licenseData, setLicenseData] = React.useState({})
  const [contestRegistrationOptions, setContestRegistrationOptions] = React.useState(makeGroupContentRegistrationOptions())
  const [noSeats, setNoSeats] = React.useState(null)
  const [register, setRegister] = React.useState(true)
  const [activeContestIds, setActiveContestIds] = React.useState([])
  const [loginState] = useLogin()
  const open = props.open
  const lmsSelected = groupFields.lmsId !== 'none'
  const promptContestRegistration = activeContestIds.includes(groupFields.missionPackId)
  const contestRegistrationValid = !promptContestRegistration || !register || (contestRegistrationOptions.firstName !== '' && contestRegistrationOptions.lastName !== '' && contestRegistrationOptions.educationalInstitution !== '' && contestRegistrationOptions.terms)

  const seatsLoading = noSeats === null

  const onFieldChange = (key, value) => {
    var copyOfFields = {...groupFields}
    copyOfFields[key] = value
    setGroupFields(copyOfFields)
  }

  const onFieldsChange = (changes) => {
    var copyOfFields = {...groupFields}
    changes.forEach(([key, val]) => {
      copyOfFields[key] = val
    })
    setGroupFields(copyOfFields)
  }

  const handleCreateGroup = () => {
    const args = {...groupFields, name: groupFields.groupName, joinActive: groupFields.genJoinCode }
    if (args.lmsId === 'none') {
      args.lmsId = null
    }

    if (contestRegistrationValid && register && promptContestRegistration) {
      args.contestRegistration = {contestId: 'hween_2024', ...contestRegistrationOptions}
    }
    return putSim('groups', loginState?.user, args)
  }

  const handleCreateGroupSuccess = () => {
    props.getGroups()
    props.handleClose()
    setGroupFields(DEFAULT_GROUP_FIELDS())
  }

  React.useEffect(() => {
    const getOwnerLicenseData = async () => {
      setFetchStatus('waiting')
      const resp = await getSim('licenses', loginState?.user)
      if (resp.status === 200){
        try {
          const data = await resp.json()
          setLicenseData(data.licenseDetails)
          setActiveContestIds(data.activeContestPackIds)
          setNoSeats(Object.keys(data.licenseDetails).length === 0)
        } catch (err) {
          console.log(err)
        }
        // const formattedLicenses = getTotalAvalibleSeats(data.message)
        setFetchStatus('success')
      } else {
        setFetchStatus('failed')
      }
    }
    if (open) {
      getOwnerLicenseData()
    }
  }, [open, loginState])

  const encodeProductMenu = (productId, licenseType) => {
    return productId + '//' + licenseType
  }

  const decodeProductMenu = (id) => {
    return id.split('//')
  }

  const createProductRows = (product) => {
    var defaultSelection
    if (product.id in licenseData){
      const types = Object.keys(licenseData[product.id]).sort()
      const items = []
      types.forEach((type) =>{
        const id = encodeProductMenu(product.id, type)
        if (!defaultSelection){
          defaultSelection = id
        }
        items.push(
          <MenuItem
            divider
            value={id}
          >
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
              <div>
                {product.title}
              </div>
              {types.length > 1 ?

                <Typography variant='body2'>
                  {type}
                </Typography>:
                null
              }
            </div>
          </MenuItem>
        )
      })
      if (groupFields.missionPackId === '' && defaultSelection){
        onProductMenuChange(defaultSelection)
      }
      return items
    } else {
      return null
    }
  }

  const onProductMenuChange = (value) => {
    const [productId, type] = decodeProductMenu(value)
    var copyOfFields = {...groupFields}
    copyOfFields.missionPackId = productId
    copyOfFields.licenseType = type
    setGroupFields(copyOfFields)
  }

  const registrationButtonDisabled =
  !contestRegistrationValid ||
  groupFields.groupName === '' ||
  noSeats ||
  groupFields.missionPackId === '' ||
  fetchStatus === 'waiting' ||
  (lmsSelected && !groupFields.lmsClassId)

  return (
    <GenericDialog
      open={open}
      onClose={props.handleClose}
      title={
        <div style={{display:'flex', alignItems:'center', gap: 5, justifyContent: 'flex-start'}}>
          <Typography variant='subtitle1' style={{paddingRight: 5}}>
              New Class
          </Typography>
          {fetchStatus === 'waiting' ?
            <CircularProgress style={{color: 'black'}} size={14}/>:
            null
          }
        </div>
      }
      buttons={
        <>
          <RespButton
            variant='contained'
            buttonComponent={FiriaGreenButton}
            onClick={handleCreateGroup}
            successCb={handleCreateGroupSuccess}
            status={createFetchStatus}
            setStatus={setCreateFetchStatus}
            placement='left'
            disabled={registrationButtonDisabled}
            resetStatus={!props.open}
          >
            {`Create ${promptContestRegistration ? 'and register ':''}Class`}
          </RespButton>
          <Button
            onClick={props.handleClose}
            variant='outlined'
          >
            Close
          </Button>
        </>
      }
    >
      <div className={classes.dialogContentContainer}>
        {seatsLoading || noSeats ?
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {seatsLoading ?
              <Typography>
                Please wait while we find your licenses!
              </Typography>:
              noSeats ?
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 25}}>
                  <Typography align='center'>
                    We couldn't find any licenses associated with your account.
                  </Typography>
                  <Typography align='center'>
                    Click here to view the license portal where you can claim purchased licenses.
                  </Typography>
                  <Button color='secondary' variant='contained'>
                    License Portal
                  </Button>
                </div>:
                null}
          </div>:
          <div style={{display: 'flex', alignItems: 'flex-start'}}>
            <div style={{
              display:'flex',
              flexDirection: 'column',
              gap: 16,
            }}>
              <OutlinedTextField
                className={classes.wideField}
                label={'Class Name'}
                onChange={ev=>onFieldChange('groupName', ev.target.value)}
                value={groupFields.groupName}
                disabled={noSeats || fetchStatus === 'waiting'}
              />
              <OutlinedCheckBox
                style={{width: '100%'}}
                checked={!!groupFields.genJoinCode}
                onClick={ev=>onFieldChange('genJoinCode', !groupFields.genJoinCode)}
                label='Allow Members To Join With Join Code'
                disabled={noSeats || fetchStatus === 'waiting'}
              />
              <OutlinedSelect
                className={classes.wideField}
                value={encodeProductMenu(groupFields.missionPackId, groupFields.licenseType)}
                onChange={ev => onProductMenuChange(ev.target.value)}
                label='Product'
                disabled={noSeats || fetchStatus === 'waiting'}
              >
                {props.packList.map(product => (
                  createProductRows(product)
                ))}
              </OutlinedSelect>
              {promptContestRegistration &&
                <OutlinedCheckBox
                  style={{width: '100%'}}
                  checked={register}
                  onClick={e => setRegister(z => !z)}
                  label={'Register for the "Haunted Code Chronicles" Contest'}
                />
              }
              {!!groupFields.missionPackId && CURATED_ASSIGNMENTS_BY_MISSION_PACK_ID[groupFields.missionPackId] !== undefined &&
                <LMSIntegrationSelectionPanel
                  lmsId={groupFields.lmsId}
                  lmsClassId={groupFields.lmsClassId}
                  setLMSId={ev => onFieldChange('lmsId', ev.target.value)}
                  lmsSelected={lmsSelected}
                  onFieldsChange={onFieldsChange}
                  disabled={noSeats || fetchStatus === 'waiting'}
                />
              }
            </div>
            {register && promptContestRegistration &&
            <>
              <div style={{marginRight: 16}}/>
              <div style={{borderLeft: '1px solid rgba(0,0,0,0.1)', paddingLeft: 16}}>
                <div>
                  <ContestRegistrationPanel {...{setContestRegistrationOptions, ...contestRegistrationOptions}}/>
                </div>
              </div>
            </>
            }
          </div>
        }
      </div>
    </GenericDialog>
  )
}

export { AddGroupDialog, DEFAULT_GROUP_FIELDS }